import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";

const WelcomePage = () => {
  const [userApp, setuserApp] = useState(null);
  const { strapiAbout, strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userApp = params.get("user");
    setuserApp(userApp);
    // console.log(userApp);
  }, []);

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Välkommen till Zwop" }} />

      <main>
        <section id="signup-success" class="ud-pt-[120px] ud-pb-20">
          <div className="ud-container">
            <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
              <div className="ud-w-full ud-px-4">
                <div
                  className="wow fadeInUp ud-mx-auto ud-max-w-[570px] ud-text-center"
                  data-wow-delay=".2s"
                >
                  <h1
                    className="
                  ud-mb-5
                  ud-text-3xl
                  ud-font-bold ud-leading-tight
                  ud-text-black
                  dark:ud-text-white
                  sm:ud-text-4xl
                  sm:ud-leading-tight
                  md:ud-text-5xl
                  md:ud-leading-tight
                "
                  >
                    Välkommen🚀
                  </h1>
                  <p
                    className="
                  md:ud-text-xxl ud-mb-4
                  ud-text-xl
                  ud-font-medium
                  ud-leading-relaxed
                  ud-text-body-color
                  dark:ud-text-white dark:ud-opacity-90
                  md:ud-leading-relaxed
                "
                  >
                    Grattis! Nu har du skapat ditt konto på Zwop.
                  </p>
                  <p
                    className="
                  ud-mb-12 ud-text-lg
                  ud-font-medium
                  ud-leading-relaxed
                  ud-text-body-color
                  dark:ud-text-white
                  dark:ud-opacity-90 md:ud-text-lg
                  md:ud-leading-relaxed
                "
                  >
                    Gå till mailet du fick och klicka på länken för att aktivera
                    ditt konto. Dubbelkolla i skräpposten i fall du inte hittar
                    något i din inkorg.
                  </p>
                  <div className="ud-flex ud-items-center ud-justify-center">
                    <Link
                      className="
                    ud-mx-2
                    ud-rounded-full
                    ud-bg-primary
                    ud-py-4
                    ud-px-8
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-transition
                    ud-duration-300
                    ud-ease-in-out
                    hover:ud-bg-opacity-80
                    "
                      to={`${process.env.APP_URL}`}
                    >
                      Logga in
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default WelcomePage;
